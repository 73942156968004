import React from 'react';
import EntityTable from './table/EntityTable';
import {HeadlineH1} from './text/Headlines';
import getReceiverStringFromMessage from '../helpers/getReceiverStringFromMessage';
import moment from 'moment';
import styled from 'styled-components';
import {AdditionalButton} from './form/FormElements';

const Preview = styled.div`
  & p {
    margin: 0;
    padding: 0;
  }
`;

const _tableConfig = () => {
  return {
    maxRows: 25,
    cols: [
      {
        header: 'Datum',
        valueKey: 'publishDateStr',
        method: publishDateStr => moment(publishDateStr).format('LL'),
        width: '10%',
        sortKeyMethod: publishDateStr => new Date(publishDateStr).getTime(),
      },
      {
        header: 'Titel',
        valueKey: '*',
        method: row => row.title,
        width: '30%',
      },
      {
        header: 'Text',
        valueKey: '*',
        method: ({textRich}) => {
          return <Preview dangerouslySetInnerHTML={{__html: textRich}} />;
        },
        width: '40%',
        filterString: ({text}) => text,
      },
      {
        header: 'Empfänger',
        valueKey: '*',
        method: row => {
          return getReceiverStringFromMessage(row);
        },
        width: '20%',
      },
    ],
  };
};
export default ({
  data: {
    allDefects,
    refetch,
    variables: {hidden},
  },
  history,
}) => (
  <div>
    <HeadlineH1>Störung/Mangel ({hidden ? 'archiviert' : 'sichtbar'})</HeadlineH1>
    <AdditionalButton
      onClick={() => refetch({hidden: !hidden})}
      style={{cursor: 'pointer', marginRight: '-200px', color: 'white'}}>
      <div>{!hidden ? 'archiviert' : 'sichtbar'}</div>
    </AdditionalButton>
    <EntityTable
      tableConfig={_tableConfig()}
      data={allDefects || []}
      msg={'Noch keine Störungen/Mangel erstellt'}
      onRowClicked={row => history.push('/defect/' + row._id)}
    />
  </div>
);
