import React, {Component} from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import {HeadlineH1, HeadlineH2} from './text/Headlines';
import ReceiverForm from './ReceiverForm';
import styled from 'styled-components';
import MediaPicker from '../components/media-picker/MediaPicker';
import MediaUploader from '../components/media-picker/MediaUploader';
import Selector from './Selector';
import YouTubePreview, {youTubeIdIsValid} from '../components/YouTubePreview';
import OptionPicker, {convertIncomingOption, convertOptionBeforeSending} from '../components/OptionPicker';
import {
  MESSAGE_MESSAGE,
  MESSAGE_NEWS,
  MESSAGE_JOB,
  MESSAGE_POLL,
  MESSAGE_VOTING,
  MESSAGE_PROJECT,
  STATUS_PLANING,
  MESSAGE_DOCUMENT,
  MESSAGE_DEFECT,
  MESSAGE_ESCOS,
} from '../constants/messageTypes';
import {COMMENT_IMAGES, IMAGES, DOCUMENTS, STATUS_ENTRY_IMAGES} from '../constants/stateKeys';
import DeleteModal from '../components/DeleteModal';
import ActionButtonRow from '../components/ActionButtonRow';
import getReceiverStringFromMessage from '../helpers/getReceiverStringFromMessage';
import filterOutNotDefinedValues from '../helpers/filterOutNotDefinedValues';
import Comments from './Comments';
import StatusSelection from './StatusSelection';
import StatusEntryPicker from './StatusEntryPicker';
import ErrorMessage from './ErrorMessage';
import convertErrors from '../helpers/errorHelper';
import Switch from '@material-ui/core/Switch';
import moment from 'moment';
import RemoveIcon from '@material-ui/icons/Delete';
import {DeleteItemIconWrapper} from './media-picker/DeleteItemIconWrapper';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {DatePicker} from 'material-ui-pickers';
import AutoComplete from './AutoComplete';
import {SortablePreview} from './SortablePreview';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RichEditor from './form/RichEditor';
import LoadingIndicator from './LoadingIndicator';
import {Fragment} from 'react';
import ArchiveModal from './ArchiveModal';

const StyledMediaPicker = styled(MediaPicker)`
  margin: 10px 0;
`;

const width = 150;

const Image = styled(LazyLoadImage)`
  width: ${width}px;
  height: 120px;
`;

const Document = styled.div`
  width: ${width}px;
  border: 1px solid lightgray;
  padding: 5px;
  text-align: center;
  word-wrap: break-word;
  background-color: white;
`;

const StyledPdfIcon = styled(PdfIcon)`
  font-size: 40px;
  margin: 7px;
`;

const SelectedImagesWrapper = styled.div`
  margin: 0 -10px;
`;

const SelectedWrapper = styled.div`
  position: relative;
  margin: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const _renderImage = ({url, title}) => <Image src={url} effect='opacity' alt={title} />;

const _renderDocument = ({_id, title}, removeDocument) => {
  const [, ...rest] = title.split('_');
  return (
    <Document>
      {removeDocument && (
        <DeleteItemIconWrapper
          onClick={() => {
            removeDocument(_id);
          }}>
          <RemoveIcon />
        </DeleteItemIconWrapper>
      )}
      <StyledPdfIcon />
      <br />
      {rest.join(' ')}
    </Document>
  );
};

const getEndDateInTwoWeeks = () =>
  moment()
    .add(2, 'w')
    .endOf('d');

const PDF_STATUS_DEFAULT = 'PDF';
const PDF_STATUS_GENERATING = 'PDF wird erstellt...';

class MessageForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messageType: undefined,
      title: '',
      text: '',
      textRich: '',
      receiver: null,
      blocks: [],
      region: null,
      projectBlock: null,
      status: STATUS_PLANING,
      statusEntries: [],
      [IMAGES]: [],
      [DOCUMENTS]: [],
      youTubeId: '',
      endDateStr: getEndDateInTwoWeeks().toISOString(),
      publishDateStr: moment()
        .startOf('d')
        .toISOString(),
      options: [],
      userCount: 0,
      comments: [],
      commentsArePrivate: false,
      question: '',
      deleteModalOpen: false,
      archiveModalOpen: false,
      toAll: props.type === MESSAGE_PROJECT,
      errors: [],
      comment: '',
      [COMMENT_IMAGES]: [],
      savingStatus: this.isNew(props) ? undefined : 'Daten werden geladen...',
      pdfUrl: undefined,
      pdfStatus: PDF_STATUS_DEFAULT,
      sendPush: false,
      pushTitle: '',
      pushMessage: '',
      linkedProject: null,
    };
  }

  isNew = (props = this.props) => !props.match.params.id;

  isArchived = () => !!this.state.archivedDateStr;

  hasMultipleBlocks = (props = this.props) =>
    props.type === MESSAGE_POLL || props.type === MESSAGE_VOTING || props.type === MESSAGE_MESSAGE;

  isConverted = messageType => messageType === MESSAGE_MESSAGE && this.props.type === MESSAGE_JOB;

  getTypeName() {
    switch (this.props.type) {
      default:
      case MESSAGE_MESSAGE:
        return 'Nachricht';
      case MESSAGE_NEWS:
        return 'News';
      case MESSAGE_PROJECT:
        return 'Projekt';
      case MESSAGE_JOB:
        return 'Job';
      case MESSAGE_POLL:
        return 'Umfrage';
      case MESSAGE_VOTING:
        return 'Abstimmung';
      case MESSAGE_DOCUMENT:
        return 'Dokument';
      case MESSAGE_DEFECT:
        return 'Störung/Mangel';
      case MESSAGE_ESCOS:
        return 'ESCOS Idee';
    }
  }

  getNewText() {
    switch (this.props.type) {
      default:
      case MESSAGE_MESSAGE:
      case MESSAGE_POLL:
      case MESSAGE_VOTING:
      case MESSAGE_NEWS:
      case MESSAGE_DEFECT:
      case MESSAGE_ESCOS:
        return 'Neue';
      case MESSAGE_PROJECT:
      case MESSAGE_DOCUMENT:
        return 'Neues';
      case MESSAGE_JOB:
        return 'Neuer';
    }
  }

  getListPath() {
    switch (this.props.type) {
      default:
      case MESSAGE_MESSAGE:
        return '/messages';
      case MESSAGE_NEWS:
        return '/news';
      case MESSAGE_PROJECT:
        return '/projects';
      case MESSAGE_JOB:
        return '/jobs';
      case MESSAGE_POLL:
        return '/polls';
      case MESSAGE_VOTING:
        return '/votings';
      case MESSAGE_DOCUMENT:
        return '/documents';
      case MESSAGE_DEFECT:
        return '/defects';
      case MESSAGE_ESCOS:
        return '/escos';
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.isNew()) return;
    let {options, endDateStr, ...message} = nextProps.messageQuery.message || {};
    const {graphQLErrors} = nextProps.messageQuery.error || {};

    this.setState(({savingStatus}) => ({
      ...filterOutNotDefinedValues(message),
      toEditor: this.isConverted(message.messageType) ? undefined : message.toEditor,
      endDateStr: endDateStr || getEndDateInTwoWeeks().toISOString(),
      options: options ? options.map(convertIncomingOption) : [],
      errors: graphQLErrors,
      savingStatus: message._id ? undefined : savingStatus,
    }));
  }

  _renderHeadline = () => {
    const typeName = this.getTypeName();
    if (this.isNew()) {
      return (
        <HeadlineH1>
          {this.getNewText()} {typeName}
        </HeadlineH1>
      );
    }
    return <HeadlineH1>{typeName} bearbeiten</HeadlineH1>;
  };

  _renderReceiver = () =>
    this.isNew() || this.isConverted(this.state.messageType)
      ? this._renderReceiverSelection()
      : this._renderReceiverDisplay();

  _renderReceiverSelection = () => {
    let {receiversQuery} = this.props;
    if (receiversQuery.loading) return;
    const {allRegions, allBlocks, allUsers} = receiversQuery;
    return (
      <ReceiverForm
        multipleBlocks={this.hasMultipleBlocks()}
        type={this.props.type}
        message={{
          ...this.state,
          blocks: this.props.type === MESSAGE_DEFECT && this.state.toEditor ? [] : this.state.blocks,
        }}
        blocks={allBlocks}
        users={allUsers}
        regions={allRegions}
        update={data => {
          console.log('update', data);
          this.setState(data);
        }}
      />
    );
  };

  _renderReceiverDisplay = () => {
    let {messageQuery} = this.props;
    if (messageQuery.loading) return;
    return <div>{getReceiverStringFromMessage(messageQuery.message)}</div>;
  };

  _renderProjectBlock = () => (this.isNew() ? this._renderProjectBlockSelection() : this._renderProjectBlockDisplay());

  _renderProjectBlockSelection = () => (
    <Selector
      items={this.props.data.allBlocks || []}
      selectedItems={this.state.projectBlock ? [this.state.projectBlock] : []}
      changeMethod={([projectBlock]) => this.setState({projectBlock})}
      renderItem={({name}) => name}
    />
  );

  _renderProjectBlockDisplay = () => this.state.projectBlock && this.state.projectBlock.name;

  _renderImagePreview = (stateKey, index) => {
    if (stateKey === STATUS_ENTRY_IMAGES) {
      return (
        <SortablePreview
          id={stateKey + index}
          data={this.state.statusEntries[index].images}
          renderItem={this._renderSelectedImage(this.removeItem(stateKey, index))}
          onRemove={this.confirmRemove(this.removeItem(stateKey, index))}
          onChange={images => {
            let entries = [...this.state.statusEntries];

            entries[index] = {
              ...entries[index],
              images,
            };

            this.setState({statusEntries: entries});
          }}
        />
      );
    }

    return (
      <SortablePreview
        id={stateKey}
        data={this.state[stateKey]}
        renderItem={this._renderSelectedImage(this.removeItem(stateKey))}
        onRemove={this.confirmRemove(this.removeItem(stateKey))}
        onChange={images => this.setState({[stateKey]: images})}
      />
    );
  };

  _renderImagePicker = (stateKey, index) => {
    if (stateKey === STATUS_ENTRY_IMAGES) {
      return (
        <StyledMediaPicker
          title='Bilder auswählen'
          items={this.props.imagesQuery.allImagesPaginated || []}
          multiple
          selectedItems={this.state.statusEntries[index].images}
          onSelected={images => {
            let entries = [...this.state.statusEntries];

            entries[index] = {
              ...entries[index],
              images,
            };

            this.setState({statusEntries: entries});
          }}
          renderItem={_renderImage}>
          <MediaUploader onUploadDone={this.handleUploadedImage} fileTypes={['jpg', 'png']} />
        </StyledMediaPicker>
      );
    }

    return (
      <StyledMediaPicker
        title='Bilder auswählen'
        items={this.props.imagesQuery.allImagesPaginated || []}
        multiple
        selectedItems={this.state[stateKey]}
        onSelected={images => this.setState({[stateKey]: images})}
        renderItem={_renderImage}>
        <MediaUploader onUploadDone={this.handleUploadedImage} fileTypes={['jpg', 'png']} />
      </StyledMediaPicker>
    );
  };

  _renderSelectedImage = removeImage => ({url, title, _id}) => (
    <SelectedWrapper key={_id}>
      <DeleteItemIconWrapper onClick={() => this.confirmRemove(removeImage)(_id)}>
        <RemoveIcon />
      </DeleteItemIconWrapper>
      <Image src={url} alt={title} />
    </SelectedWrapper>
  );

  _renderSelectedImages = (images, removeImage) => (
    <SelectedImagesWrapper>{images.map(this._renderSelectedImage(removeImage))}</SelectedImagesWrapper>
  );
  _renderDocumentPicker = () => (
    <StyledMediaPicker
      title='Dokumente auswählen'
      items={this.props.documentsQuery.allDocuments || []}
      multiple
      selectedItems={this.state.documents}
      onSelected={documents =>
        this.setState({
          documents,
        })
      }
      renderItem={_renderDocument}>
      <MediaUploader onUploadDone={this.handleUploadedDocument} fileTypes={['pdf']} />
    </StyledMediaPicker>
  );
  _renderSelectedDocument = removeDocument => document => (
    <SelectedWrapper key={document._id}>
      {_renderDocument(document, this.confirmRemove(removeDocument))}
    </SelectedWrapper>
  );
  _renderYouTubeIdInput = () => (
    <TextField
      label='YouTube ID'
      type='text'
      fullWidth
      value={this.state.youTubeId || ''}
      onChange={({target: {value}}) => {
        this.setState({
          youTubeId: value,
        });
      }}
    />
  );
  _renderCommentInput = () => (
    <div>
      <TextField
        label='Neuer Kommentar'
        type='text'
        multiline
        fullWidth
        rows={1}
        value={this.state.comment || ''}
        onChange={({target: {value}}) => this.setState({comment: value})}
      />
      {this._renderImagePicker(COMMENT_IMAGES)}
      <SortablePreview
        id={COMMENT_IMAGES}
        data={this.state[COMMENT_IMAGES]}
        renderItem={this._renderSelectedImage(this.removeItem(COMMENT_IMAGES))}
        onRemove={this.confirmRemove(this.removeItem(COMMENT_IMAGES))}
        onChange={images => this.setState({[COMMENT_IMAGES]: images})}
      />
      <Button variant='contained' onClick={this._saveComment} disabled={!this.state.comment}>
        Kommentar senden
      </Button>
    </div>
  );
  _saveComment = () => {
    this.props.addComment(this.state.comment, this.state[COMMENT_IMAGES]);
    this.setState({comment: '', [COMMENT_IMAGES]: []});
  };
  lastSave = undefined;
  _save = () => {
    const now = Date.now();
    if (this.lastSave && now - this.lastSave < 1000) return;
    this.lastSave = now;
    this.setState({savingStatus: 'Daten werden gespeichert...'});
    const {
      receiver,
      blocks,
      region,
      projectBlock,
      statusEntries,
      images,
      documents,
      youTubeId,
      options,
      pushTitle,
      pushMessage,
      linkedProject,
      ...state
    } = filterOutNotDefinedValues(this.state);
    this.props
      .save({
        ...state,
        [this.hasMultipleBlocks() ? 'blocks' : 'block']: this.hasMultipleBlocks()
          ? blocks.map(block => (typeof block === 'object' ? block._id : block))
          : blocks && blocks.length === 1
          ? blocks[0]._id
          : null,
        receiver: receiver ? receiver._id : null,
        region: region ? region._id : null,
        projectBlock: projectBlock ? projectBlock._id : null,
        statusEntries: statusEntries.map(({text, date, images, done}) => ({
          text,
          date,
          images: images.map(image => image._id),
          done,
        })),
        images: images.map(media => media._id),
        documents: documents.map(doc => doc._id),
        youTubeId: youTubeIdIsValid(youTubeId) ? youTubeId : undefined,
        pushTitle: pushTitle ? pushTitle : undefined,
        pushMessage: pushMessage ? pushMessage : undefined,
        linkedProject: linkedProject ? linkedProject._id : null,
        options: options
          .map(convertOptionBeforeSending)
          .filter(({isValid}) => isValid)
          .map(({option}) => option),
      })
      .then(() => {
        this.setState({savingStatus: undefined});
        this._goBackToList();
      })
      .catch(rawErrors => {
        let errors = convertErrors(rawErrors);
        this.setState({errors, savingStatus: undefined});
        console.log('there was an error sending the query', errors);
      });
  };
  handleUploadedImage = image =>
    this.props.imagesQuery.updateQuery(({allImagesPaginated, ...prev}) => ({
      ...prev,
      allImagesPaginated: [{...image, __typename: 'Media'}, ...allImagesPaginated],
    }));

  handleUploadedDocument = document =>
    this.props.documentsQuery.updateQuery(({allDocuments, ...prev}) => ({
      ...prev,
      allDocuments: [{...document, __typename: 'Media'}, ...allDocuments],
    }));

  removeItem = (stateKey, index) => id => {
    if (stateKey === STATUS_ENTRY_IMAGES) {
      let entries = [...this.state.statusEntries];
      let filteredImages = entries[index].images.filter(({_id}) => _id !== id);

      entries[index] = {
        ...entries[index],
        images: filteredImages,
      };

      this.setState({statusEntries: entries});
    } else {
      this.setState({[stateKey]: this.state[stateKey].filter(({_id}) => _id !== id)});
    }
  };

  confirmRemove = removeMethod => id => this.setState({deleteAttachmentMethod: () => removeMethod(id)});

  _renderQuestionInput = () => (
    <TextField
      label='Frage*'
      type='text'
      fullWidth
      value={this.state.question}
      multiline
      rows={1}
      onChange={event => {
        this.setState({
          question: event.target.value,
        });
      }}
    />
  );
  _renderOptionsPicker() {
    let {options} = this.state;
    return (
      <OptionPicker
        options={options}
        addOption={option =>
          this.setState({
            options: [...options, option],
          })
        }
        allImages={this.props.imagesQuery.allImagesPaginated || []}
        handleImageUpload={this.handleUploadedImage}
        renderImage={_renderImage}
        renderSelectedImages={this._renderSelectedImages}
        onChangeOption={(i, option) => {
          let newOptions = options.slice(0);
          newOptions[i] = option;
          this.setState({
            options: newOptions,
          });
        }}
        onRemoveOption={selected =>
          this.setState({
            options: options.filter((_, i) => i !== selected),
          })
        }
      />
    );
  }
  _renderParticipation = () => {
    const {userCount, options} = this.state;
    if (this.isNew() || !options || !options.length) return null;
    let answerCount = options.reduce((count, {answers}) => {
      if (answers) count += answers.length;
      return count;
    }, 0);
    return (
      <div>
        <HeadlineH2>Beteiligung</HeadlineH2>
        <div>
          {answerCount} / {userCount} Benutzern
        </div>
        <div>{Math.round((answerCount / userCount) * 100)}% Wahlbeteiligung</div>
      </div>
    );
  };

  _renderJobStatus = () => {
    const {jobAcceptedDateStr, jobAcceptedUser, jobFinishedDateStr} = this.state;
    if (!jobAcceptedDateStr) return 'Der Job wurde noch nicht angenommen.';
    let {firstName, lastName} = jobAcceptedUser;
    let acceptedDate = moment(jobAcceptedDateStr).format('DD.MM.YYYY');
    let result = `Der Job wurde von ${firstName} ${lastName} am ${acceptedDate} angenommen`;
    if (jobFinishedDateStr) {
      let finishedDate = moment(jobFinishedDateStr).format('DD.MM.YYYY');
      return result + ` und am ${finishedDate} abgeschlossen.`;
    } else {
      return result + '.';
    }
  };

  _renderTransformToJobButton = () => {
    const {blocks, _id} = this.state;
    const {type} = this.props;
    if (_id && blocks.length !== 0 && type === MESSAGE_MESSAGE) {
      return <Button onClick={() => this.props.history.push(`/job/${_id}`)}>in Job umwandeln</Button>;
    }
    return null;
  };
  _goBackToList = () => this.props.history.replace(this.getListPath());
  _requestPdfUrl = () => {
    this.setState({pdfStatus: PDF_STATUS_GENERATING});
    this.props.createPdf().then(({data: {createPdf}}) => this.setState({pdfUrl: createPdf, pdfStatus: 'PDF öffnen'}));
  };
  _openPdf = () => {
    window.open(this.state.pdfUrl, '_blank');
    this.setState({pdfStatus: PDF_STATUS_DEFAULT, pdfUrl: undefined});
  };
  _renderPdfButton = () => {
    const {pdfUrl, pdfStatus} = this.state;
    return (
      <Button disabled={pdfStatus === PDF_STATUS_GENERATING} onClick={pdfUrl ? this._openPdf : this._requestPdfUrl}>
        {pdfStatus}
      </Button>
    );
  };
  _renderPushForm = () => {
    return (
      <div>
        <HeadlineH2>Push Notification</HeadlineH2>
        <FormControlLabel
          control={
            <Switch
              checked={this.state.sendPush}
              onChange={(_, sendPush) =>
                this.setState({
                  sendPush,
                })
              }
            />
          }
          label={this.state.sendPush ? 'Push wird versendet' : 'Kein Push'}
        />
        <TextField
          label='Push Titel*'
          type='text'
          fullWidth
          value={this.state.pushTitle}
          disabled={!this.state.sendPush}
          onChange={event => {
            this.setState({
              pushTitle: event.target.value.slice(0, 50),
            });
          }}
        />
        <TextField
          label='Push Nachricht*'
          type='text'
          fullWidth
          value={this.state.pushMessage}
          disabled={!this.state.sendPush}
          onChange={event => {
            this.setState({
              pushMessage: event.target.value.slice(0, 150),
            });
          }}
        />
      </div>
    );
  };
  render() {
    let {savingStatus} = this.state;
    let savingDisabled = !!savingStatus;
    const {type} = this.props;
    return (
      <div>
        <ErrorMessage errors={this.state.errors} clearErrors={() => this.setState({errors: []})} />
        {this.props.messageQuery && this.props.messageQuery.loading ? (
          <div>
            <br />
            <LoadingIndicator />
          </div>
        ) : (
          <>
            {this._renderHeadline()}
            <Row>
              <DatePicker
                style={{width: 300}}
                label='Datum der Veröffentlichung'
                value={this.state.publishDateStr ? new Date(this.state.publishDateStr) : null}
                cancelLabel='Abbrechen'
                okLabel='OK'
                disablePast
                format='L'
                helperText={undefined}
                error={undefined}
                onChange={date => {
                  this.setState({
                    publishDateStr: moment(date)
                      .startOf('d')
                      .toISOString(),
                  });
                }}
              />
              {(type === MESSAGE_JOB || type === MESSAGE_POLL || type === MESSAGE_VOTING) && (
                <DatePicker
                  label='Ablaufdatum'
                  disablePast
                  style={{
                    width: 300,
                    marginLeft: 20,
                  }}
                  onChange={date => {
                    this.setState({
                      endDateStr: moment(date)
                        .endOf('d')
                        .toISOString(),
                    });
                  }}
                  format='L'
                  helperText={undefined}
                  error={undefined}
                  value={this.state.endDateStr ? new Date(this.state.endDateStr) : null}
                  okLabel='OK'
                  cancelLabel='Abbrechen'
                />
              )}
            </Row>
            <TextField
              autoFocus
              label='Titel*'
              type='text'
              fullWidth
              value={this.state.title}
              onChange={event => {
                this.setState({
                  title: event.target.value,
                });
              }}
            />
            <RichEditor
              label='Text*'
              placeholder='Text eingeben ...'
              value={this.state.textRich}
              onChange={({textRich, text}) => this.setState({textRich, text})}
            />
            {(type === MESSAGE_POLL || type === MESSAGE_VOTING) && (
              <div>
                {this._renderQuestionInput()}
                {this._renderOptionsPicker()}
                {this._renderParticipation()}
              </div>
            )}
            {type === MESSAGE_JOB && (
              <div>
                <HeadlineH2>Status</HeadlineH2>
                {this._renderJobStatus()}
              </div>
            )}
            {type !== MESSAGE_DEFECT ? (
              <Fragment>
                {type === MESSAGE_PROJECT ? (
                  <div>
                    <HeadlineH2>Wohnanlage</HeadlineH2>
                    {this._renderProjectBlock()}
                    <HeadlineH2>Status</HeadlineH2>
                    <StatusSelection status={this.state.status} onChange={status => this.setState({status})} />
                    <StatusEntryPicker
                      statusEntries={this.state.statusEntries}
                      onChange={statusEntries =>
                        this.setState({
                          statusEntries,
                        })
                      }
                      imagePicker={this._renderImagePicker}
                      imagePreview={this._renderImagePreview}
                    />
                  </div>
                ) : (
                  <div>
                    <HeadlineH2>Empfänger</HeadlineH2>
                    {this._renderReceiver()}
                  </div>
                )}
              </Fragment>
            ) : (
              <div>
                <HeadlineH2>Empfänger</HeadlineH2>
                {this._renderReceiverDisplay()}
                {this.props.messageQuery &&
                  this.props.messageQuery.message &&
                  this.props.messageQuery.message.toEditor &&
                  this._renderReceiverSelection()}
              </div>
            )}
            <HeadlineH2>Bilder</HeadlineH2>
            {this._renderImagePicker(IMAGES)}
            {this._renderImagePreview(IMAGES)}
            <HeadlineH2>Dokumente</HeadlineH2>
            {this._renderDocumentPicker()}
            <SortablePreview
              id={DOCUMENTS}
              data={this.state[DOCUMENTS]}
              renderItem={this._renderSelectedDocument(this.removeItem(DOCUMENTS))}
              onRemove={this.confirmRemove(this.removeItem(DOCUMENTS))}
              onChange={documents => this.setState({[DOCUMENTS]: documents})}
            />
            {(type === MESSAGE_NEWS || type === MESSAGE_PROJECT) && (
              <div>
                {this._renderYouTubeIdInput()}
                <YouTubePreview youTubeId={this.state.youTubeId} />
              </div>
            )}
            {type !== MESSAGE_ESCOS && this._renderPushForm()}
            {(type === MESSAGE_MESSAGE || type === MESSAGE_NEWS || type === MESSAGE_DOCUMENT) && (
              <div>
                <HeadlineH2>Verlinktes Projekt</HeadlineH2>
                <AutoComplete
                  id='project-auto-complete'
                  label='Projekt auswählen'
                  availableItems={this.props.data.allProjects || []}
                  getId={item => item && item._id}
                  getLabel={item => (item && item.title) || ''}
                  onChange={linkedProject => this.setState({linkedProject})}
                  selectedItem={this.state.linkedProject}
                />
              </div>
            )}
            {type !== MESSAGE_PROJECT && type !== MESSAGE_NEWS && type !== MESSAGE_ESCOS && (
              <div>
                <HeadlineH2>Kommentare</HeadlineH2>
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.commentsArePrivate}
                      onChange={(_, commentsArePrivate) =>
                        this.setState({
                          commentsArePrivate,
                        })
                      }
                    />
                  }
                  disabled={!this.isNew()}
                  label={
                    this.state.commentsArePrivate
                      ? 'Kommentare sind privat und somit nur im Backend ersichtlich.'
                      : 'Kommentare sind öffentlich, sie werden jedem Benutzer, der die Nachricht sieht, angezeigt.'
                  }
                />
                {!this.isNew() && (
                  <div>
                    <Comments comments={this.state.comments} />
                    {this._renderCommentInput()}
                  </div>
                )}
              </div>
            )}
            <ActionButtonRow>
              <Button onClick={this._goBackToList}>Zurück zur Übersicht</Button>
              <div>
                {!this.isNew() && this._renderPdfButton()}
                {type === MESSAGE_PROJECT ? (
                  <Fragment>
                    {!this.isNew() && !this.isArchived() && (
                      <Button onClick={() => this.setState({archiveModalOpen: true})}>archivieren</Button>
                    )}
                  </Fragment>
                ) : (
                  <Fragment>
                    {!this.isNew() && <Button onClick={() => this.setState({deleteModalOpen: true})}>löschen</Button>}
                  </Fragment>
                )}
                {this._renderTransformToJobButton()}
                <Button color='primary' onClick={this._save} disabled={savingDisabled}>
                  {savingDisabled ? savingStatus : 'speichern'}
                </Button>
              </div>
            </ActionButtonRow>
            {/* this one is for deleting images and documents*/}
            <DeleteModal
              open={!!this.state.deleteAttachmentMethod}
              onCancel={() =>
                this.setState({
                  deleteAttachmentMethod: false,
                })
              }
              onConfirm={() => {
                this.state.deleteAttachmentMethod();
                this.setState({deleteAttachmentMethod: null});
              }}
            />
            <DeleteModal
              open={this.state.deleteModalOpen}
              onCancel={() =>
                this.setState({
                  deleteModalOpen: false,
                })
              }
              onConfirm={() => this.props.archive().then(this._goBackToList)}
            />
            <ArchiveModal
              open={this.state.archiveModalOpen}
              onCancel={() =>
                this.setState({
                  archiveModalOpen: false,
                })
              }
              onConfirm={() => this.props.archive().then(this._goBackToList)}
            />
          </>
        )}
      </div>
    );
  }
}
export default MessageForm;
