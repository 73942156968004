import gql from 'graphql-tag';

export const SimpleBlock = gql`
  fragment SimpleBlock on Block {
    _id
    name
  }
`;

export const SimpleReceiver = gql`
  fragment SimpleReceiver on User {
    _id
    firstName
    lastName
  }
`;

export const SimpleRegion = gql`
  fragment SimpleRegion on Region {
    _id
    name
  }
`;

export const Region = gql`
  fragment Region on Region {
    ...SimpleRegion
    description
  }
  ${SimpleRegion}
`;

export const User = gql`
  fragment User on User {
    _id
    createDateStr
    active
    firstName
    lastName
    email
    phone
    role
    blocks {
      ...SimpleBlock
    }
    regions {
      ...SimpleRegion
    }
  }
  ${SimpleBlock}
  ${SimpleRegion}
`;

export const ListBlock = gql`
  fragment ListBlock on Block {
    ...SimpleBlock
    region {
      ...SimpleRegion
    }
  }
  ${SimpleBlock}
  ${SimpleRegion}
`;

export const Block = gql`
  fragment Block on Block {
    ...ListBlock
    description
    street
    number
    zipCode
    city
    hidden
  }
  ${ListBlock}
`;

export const SimpleMessage = gql`
  fragment SimpleMessage on Message {
    _id
    title
    text
    textRich
    publishDateStr
    archivedDateStr
    toAll
    toEditor
    receiver {
      ...SimpleReceiver
    }
    blocks {
      ...SimpleBlock
    }
    region {
      ...SimpleRegion
    }
    commentsArePrivate
    linkedProject {
      _id
      title
    }
  }
  ${SimpleBlock}
  ${SimpleRegion}
  ${SimpleReceiver}
`;

export const SimpleMedia = gql`
  fragment SimpleMedia on Media {
    _id
    title
    url
  }
`;

export const Attachments = gql`
  fragment Attachments on Message {
    images(width: 240, height: 180) {
      ...SimpleMedia
    }
    documents {
      ...SimpleMedia
    }
  }
  ${SimpleMedia}
`;

export const SimpleOption = gql`
  fragment SimpleOption on Option {
    _id
    type
    text
    image(width: 240, height: 180) {
      ...SimpleMedia
    }
    youTubeId
  }
  ${SimpleMedia}
`;

export const Image = gql`
  fragment Image on Media {
    title
    url
  }
`;

export const Comment = gql`
  fragment Comment on Comment {
    _id
    text
    createdAt
    createUser {
      firstName
      lastName
    }
    images(width: 800) {
      ...Image
    }
  }
  ${Image}
`;
