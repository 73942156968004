import React from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

function EntityTableHead(props) {
  const {order, orderBy, onRequestSort} = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.cols.map(
          ({header, width}, idx) => (
            <TableCell key={idx} style={{width}} sortDirection={orderBy === idx ? order : false}>
              <Tooltip title='Sort' placement='bottom-start' enterDelay={300}>
                <TableSortLabel active={orderBy === idx} direction={order} onClick={createSortHandler(idx)}>
                  {header}
                </TableSortLabel>
              </Tooltip>
            </TableCell>
          ),
          this
        )}
      </TableRow>
    </TableHead>
  );
}

const ColsPropType = PropTypes.arrayOf(
  PropTypes.shape({
    header: PropTypes.string,
    valueKey: PropTypes.string,
    method: PropTypes.func,
    width: PropTypes.any,
    sortKeyMethod: PropTypes.func,
    filterString: PropTypes.func,
  })
);

EntityTableHead.propTypes = {
  cols: ColsPropType,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.number.isRequired,
};

const Wrapper = styled.div`
  width: 100%;
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const Search = styled(TextField)`
  width: 300px;
`;

function sort(orderBy, order, {computedCols: colsA}, {computedCols: colsB}) {
  const {sortKey: sKA} = colsA[orderBy];
  const {sortKey: sKB} = colsB[orderBy];
  return (sKA < sKB ? -1 : sKA > sKB ? 1 : 0) * (order === 'desc' ? -1 : 1);
}

const doesFilterMatch = (filter, computedCols) =>
  computedCols.some(({filterKey}) => filterKey && filterKey.search(new RegExp(filter, 'i')) > -1);

function computeRows(filter, orderBy, order, cols, data) {
  const computedRows = data.reduce((computedRows, item) => {
    const computedCols = cols.map(conf => {
      let value;
      if (conf.valueKey === '*') {
        value = item;
      } else {
        value = item[conf.valueKey];
      }
      const computed = conf.method(value);
      let sortKey = conf.sortKeyMethod ? conf.sortKeyMethod(value) : computed;
      if (sortKey) sortKey = sortKey.toString().toUpperCase();
      const filterKey = conf.filterString ? conf.filterString(value) : computed;
      return {computed, sortKey, filterKey};
    });
    if (!filter || doesFilterMatch(filter, computedCols)) {
      computedRows.push({computedCols, item});
    }
    return computedRows;
  }, []);
  if (orderBy > -1) computedRows.sort(sort.bind(null, orderBy, order));
  return computedRows;
}

function EntityTable(props) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState(-1);
  const [filter, setFilter] = React.useState('');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(props.tableConfig.maxRows || 5);

  function handleRequestSort(event, property) {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  }

  function handleFilterChange({target}) {
    setPage(0);
    setFilter(target.value);
  }

  function handleChangePage(event, newPage) {
    setPage(newPage);
  }

  function handleChangeRowsPerPage(event) {
    setRowsPerPage(event.target.value);
  }

  const {cols} = props.tableConfig;

  const computedRows = computeRows(filter, orderBy, order, cols, props.data);

  const emptyRows =
    props.fillEmptyRows && rowsPerPage - Math.min(rowsPerPage, computedRows.length - page * rowsPerPage);

  return (
    <Wrapper>
      <Toolbar>
        <Spacer />
        <Search type='text' name='search' placeholder='Suchen' onChange={handleFilterChange} value={filter} />
      </Toolbar>
      <Table aria-labelledby='tableTitle'>
        <EntityTableHead cols={cols} order={order} orderBy={orderBy} onRequestSort={handleRequestSort} />
        <TableBody>
          {// stableSort(props.data, getSorting(order, orderBy))
          computedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((
            {computedCols, item},
            idx /*Todo: comute id at the beginning*/
          ) => (
            <TableRow hover onClick={() => props.onRowClicked(item)} tabIndex={-1} key={idx}>
              {cols.map((conf, colIdx) => {
                const {computed} = computedCols[colIdx];
                return (
                  <TableCell key={idx + '_' + colIdx} component='th' scope='row'>
                    {computed}
                  </TableCell>
                );
              })}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{height: 49 * emptyRows}}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={computedRows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        backIconButtonProps={{
          'aria-label': 'Previous Page',
        }}
        nextIconButtonProps={{
          'aria-label': 'Next Page',
        }}
        labelRowsPerPage='Zeilen pro Seite:'
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Wrapper>
  );
}

EntityTable.propTypes = {
  data: PropTypes.array,
  fillEmptyRows: PropTypes.bool,
  tableConfig: PropTypes.shape({
    maxRows: PropTypes.number,
    cols: ColsPropType,
  }),
  onRowClicked: PropTypes.func,
};

export default EntityTable;
