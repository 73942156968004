import Defects from '../components/Defects';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const DefectsQuery = gql`
  query allDefects($hidden: Boolean!) {
    allDefects(hidden: $hidden) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export default graphql(DefectsQuery, {
  options: {
    variables: {hidden: false},
    fetchPolicy: 'network-only',
  },
})(Defects);
