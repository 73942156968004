import gql from 'graphql-tag';
import {SimpleMedia, SimpleBlock, SimpleMessage} from './fragments';

export const AllImagesPaginatedQuery = gql`
  query allImagesPaginated($messageId: String) {
    allImagesPaginated(width: 300, height: 240, messageId: $messageId) {
      ...SimpleMedia
    }
  }
  ${SimpleMedia}
`;

export const AllImagesPaginatedQueryConfig = {
  name: 'imagesQuery',
  options: props => ({
    variables: {messageId: props.match.params.id},
    fetchPolicy: 'network-only',
  }),
};

export const AllDocumentsQuery = gql`
  query allDocuments {
    allDocuments {
      ...SimpleMedia
    }
  }
  ${SimpleMedia}
`;

export const AllDocumentsQueryConfig = {name: 'documentsQuery'};

export const MessageQueryConfig = {
  name: 'messageQuery',
  options: props => ({
    variables: {_id: props.match.params.id},
    fetchPolicy: 'network-only',
  }),
  props: ({messageQuery: {news, project, poll, job, voting, messageDocument, defect, escos, ...data}, ...props}) => ({
    messageQuery: {message: news || project || poll || job || voting || messageDocument || defect || escos, ...data},
    ...props,
  }),
};

export const JobReceiversQuery = gql`
  query receivers {
    allBlocks(hidden: false) {
      ...SimpleBlock
    }
    allUsers {
      _id
      email
      firstName
      lastName
    }
  }
  ${SimpleBlock}
`;

export const ReceiversQueryConfig = {name: 'receiversQuery', options: {fetchPolicy: 'network-only'}};

export const AllProjectsQuery = gql`
  query allProjects($archived: Boolean) {
    allProjects(archived: $archived) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;
