import Documents from '../components/Documents';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const DocumentsQuery = gql`
  query allMessageDocuments {
    allMessageDocuments {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export default graphql(DocumentsQuery)(Documents);
