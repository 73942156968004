import {graphql} from 'react-apollo';
import Projects from '../components/Projects';
import {AllProjectsQuery} from '../qql-shared/queries';

export default graphql(AllProjectsQuery, {
  options: {
    variables: {archived: false},
    fetchPolicy: 'network-only',
  },
})(Projects);
