import News from '../components/News';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const NewsQuery = gql`
  query allNews($hidden: Boolean!) {
    allNews(hidden: $hidden) {
      ...SimpleMessage
      endDateStr
    }
  }
  ${SimpleMessage}
`;

export default graphql(NewsQuery, {
  options: {
    variables: {hidden: false},
    fetchPolicy: 'network-only',
  },
})(News);
