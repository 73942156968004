import Votings from '../components/Votings';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const VotingsQuery = gql`
  query allVotings($hidden: Boolean!) {
    allVotings(hidden: $hidden) {
      ...SimpleMessage
      endDateStr
    }
  }
  ${SimpleMessage}
`;

export default graphql(VotingsQuery, {
  options: {
    variables: {hidden: false},
    fetchPolicy: 'network-only',
  },
})(Votings);
