import Escos from '../components/Escos';
import {graphql} from 'react-apollo';
import gql from 'graphql-tag';
import {SimpleMessage} from '../qql-shared/fragments';

const EscosQuery = gql`
  query allEscos($hidden: Boolean!) {
    allEscos(hidden: $hidden) {
      ...SimpleMessage
    }
  }
  ${SimpleMessage}
`;

export default graphql(EscosQuery, {
  options: {
    variables: {hidden: false},
    fetchPolicy: 'network-only',
  },
})(Escos);
