import {graphql, compose} from 'react-apollo';
import {
  AddCommentMutation,
  AddCommentMutationConfig,
  ArchiveMutation,
  ArchiveMutationConfig,
  CreatePdfMutation,
  CreatePdfMutationConfig,
  ArchiveMultipleMutation,
  ArchiveMultipleMutationConfig,
} from './mutations';
import {
  AllDocumentsQuery,
  AllDocumentsQueryConfig,
  AllImagesPaginatedQuery,
  AllImagesPaginatedQueryConfig,
} from './queries';

export default compose(
  graphql(AllDocumentsQuery, AllDocumentsQueryConfig),
  graphql(AllImagesPaginatedQuery, AllImagesPaginatedQueryConfig),
  graphql(ArchiveMutation, ArchiveMutationConfig),
  graphql(ArchiveMultipleMutation, ArchiveMultipleMutationConfig),
  graphql(AddCommentMutation, AddCommentMutationConfig),
  graphql(CreatePdfMutation, CreatePdfMutationConfig)
);
