import React from 'react';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div`
  margin-top: 20px;
`;

const CommentHeader = styled.div`
  margin-top: 20px;
  font-size: 20px;
`;

const CommentText = styled.div`
  white-space: pre-wrap;
`;

const CommentImages = styled.div`
  margin-top: 20px;
  img {
    margin-bottom: 10px;
  }
`;

const renderImages = images => {
  return images.map((image, idx) => {
    return <img src={image.url} alt={image.title} key={idx} />;
  });
};

export default ({comments}) => (
  <Wrapper>
    {comments && comments.length > 0
      ? comments.map((comment, idx) => {
          const {text, images, createdAt} = comment;
          const {firstName, lastName} = comment.createUser;
          return (
            <div key={idx}>
              <CommentHeader>
                {firstName} {lastName} am {moment(createdAt).format('DD.MM.YYYY')}
              </CommentHeader>
              <CommentText>{text}</CommentText>
              <CommentImages>{renderImages(images)}</CommentImages>
              {idx !== comments.length - 1 && <hr />}
            </div>
          );
        })
      : 'Noch keine Kommentare vorhanden.'}
  </Wrapper>
);
